
import { Component, Inject, Vue } from 'vue-property-decorator';
import Vue2Filters from 'vue2-filters';
import AlertService from '@/shared/alert/alert.service';
import { IPercentage, Percentage } from '@/shared/model/percentage.model';
import PercentageService from '@/entities/percentage/percentage.service';
import { maxValue, minValue, numeric, required } from 'vuelidate/lib/validators';
import AuthService from '@/account/auth.service';

const validations: any = {
  percentage: {
    value: {
      required,
      numeric,
      min: minValue(0),
      max: maxValue(100),
    },
  },
};
@Component({
  mixins: [Vue2Filters.mixin],
  validations,
})
export default class PercentageSettings extends Vue {
  public isSaving = false;
  public percentage: IPercentage = new Percentage();
  public isLoading = false;
  public percentages: IPercentage[] = [];
  public percentageToRemove: IPercentage = null;
  public percentageToRemoveIndex: number = null;
  public isAdmin = false;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('percentageService') private percentageService: () => PercentageService;
  @Inject('authService') private authService: () => AuthService;

  public mounted(): void {
    this.init();
  }

  /**
   * adds a new percentage
   */
  public addPercentage(): void {
    this.percentages.push(new Percentage());
  }

  public closeDeleteDialog(): void {
    (this.$refs.removeEntity as any).hide();
  }

  public prepareRemove(instance: IPercentage, index: number): void {
    this.percentageToRemoveIndex = index;
    this.percentageToRemove = instance;
    if (this.$refs.removeEntity as any) {
      (this.$refs.removeEntity as any).show();
    }
  }

  /**
   * removes item from list or from database if already persisted
   */
  public async removePercentage() {
    if (!this.percentageToRemove.id && this.percentageToRemoveIndex != null) {
      this.percentages.splice(this.percentageToRemoveIndex, 1);
      this.closeDeleteDialog();
      this.percentageToRemove = null;
      this.percentageToRemoveIndex = null;
      this.alertService().showInfo(this, 'Der Prozentsatz wurde erfolgreich gelöscht ');
      return;
    }
    try {
      await this.percentageService().delete(this.percentageToRemove.id);
      this.percentageToRemove = null;
      this.percentageToRemoveIndex = null;
      this.init().then();
      this.closeDeleteDialog();
      this.alertService().showInfo(this, 'Der Prozentsatz wurde erfolgreich gelöscht ');
    } catch (error) {
      let errorMessage = 'Der Prozentsatz konnte nicht gelöscht werden';
      if (error.response && error.response.data && error.response.data.title) {
        errorMessage = error.response.data.title;
      }
      this.alertService().showError(this, errorMessage);
    }
  }

  /**
   * saves and updates all percentages in list
   */
  async saveAllPercentages() {
    if (!this.percentages || this.percentages.length === 0) {
      return;
    }
    try {
      this.validateList(this.percentages, true);
      this.saveAll(this.percentages).then();
    } catch (e) {
      this.alertService().showError(this, e.message);
      return;
    }
  }

  /**
   * validates a percentage
   * must have fields: value
   * -value >=0 && value <= 100
   * @param percentage
   * @private
   */
  public validatePercentage(percentage: IPercentage) {
    if (percentage.value == null) {
      throw new Error('Es wurde kein Wert hinterlegt');
    }
    if (percentage.value < 0 || percentage.value > 100) {
      throw new Error('Es sind nur Prozentsätze zwischen 0 und 100 erlaubt');
    }
  }

  public isValid(percentage: IPercentage): boolean {
    return this.validationString(percentage) == null;
  }

  /**
   * returns the error/validation String for given percentage
   * if the percentage is valid null is returend
   * @param percentage
   * @private
   */
  public validationString(percentage: IPercentage): string {
    try {
      this.validatePercentage(percentage);
    } catch (e) {
      return e.message;
    }
    return null;
  }

  /**
   * checks if every percentage is valid,
   * if everything is ok it returns true, if any percentage or the list is invalid it returns false
   * @private
   */
  public isSaveButtonEnabled(): boolean {
    for (const percentage of this.percentages) {
      try {
        this.validatePercentage(percentage);
      } catch (e) {
        return false;
      }
    }
    try {
      this.validateList(this.percentages);
    } catch (e) {
      return false;
    }
    return true;
  }

  private async saveAll(percentages: IPercentage[]) {
    try {
      await this.percentageService().saveAll(percentages);
      this.alertService().showSuccess(this, 'Die Prozentsätze wurden erfolgreich gespeichert');
      this.init().then();
    } catch (e) {
      this.alertService().showError(
        this,
        'Beim Speichern der Prozentsätze ist ein Fehler aufgetreten'
      );
    }
  }

  /**
   * loads  all percentages
   * @private
   */
  private async init() {
    this.isLoading = true;
    try {
      this.isAdmin = await this.authService().isAdmin();
      this.percentages = (await this.percentageService().retrieve({ sort: ['value'] })).data;
    } catch (e) {
      this.alertService().showError(this, 'Beim Laden der Prozentsätze ist ein Fehler aufgetreten');
    }
    this.isLoading = false;
  }

  /**
   * validates list of percentages : every percentage is allowed only once
   * @param percentages list of percentages
   * @param validateSingle check field if every single item should be validated
   * @private
   */
  private validateList(percentages: IPercentage[], validateSingle = false) {
    if (percentages == null) {
      return;
    }
    const valueArr = percentages.map(item => item.value);
    const isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx;
    });
    if (isDuplicate) {
      throw new Error('Bitte verwenden Sie jeden Prozentsatz ausschließlich 1-Mal');
    }
    //if not every single percentage needs to be validated return
    if (!validateSingle) {
      return;
    }
    //validate every single percentage
    for (const perc of percentages) {
      this.validatePercentage(perc);
    }
  }
}
